import React from 'react';
import { Link } from "gatsby"
import styled from "styled-components"
// import ScrollspyNav from "react-scrollspy-nav"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NabbarTop from '../components/NavbarTop';
import { Container, Row, Col, Card, Tabs, Tab, Accordion, Modal, Button, Navbar, Nav } from 'react-bootstrap'
import { Section, ListBulletGroup, ListBulletItemGroup } from '../components/Section'
import { PhoneIcon, ArrowCircleRight, MeasureIcon, FacebookIcon, EmailIcon, MapIcon} from "../components/Icons"
import { Logo } from '../components/Icons';

import Image from "../components/image"
import FooterPattern from "../images/footer-pattern.png"
import BrandLogo from "../images/brand-logo.png"

import CallActionBg from "../images/cta-red-bg.png"
import FinancingBg from "../images/financing-bg.png"
import NatureBg from "../images/nature-bg.jpg"
import ProductBg from "../images/product-bg.png"
import WhyChooseBg from "../images/why-choose-bg.png"
import HeroNatureBg from "../images/hero-nature-bg.png"
import TopPinGraphic from "../images/top-pin-graphic.png"
import TopLeftGraphic from "../images/top-left-graphic.png"
import BottomRightGraphic from "../images/bottom-right-graphic.png"

import GetTouchForm from "../components/GetTouchForm"
import {PhoneCallIcon, CustomDesignIcon, DedicatedTeamIcon, ClipboardIcon, DepositIcon, GarageCategoryIcon, FairPriceIcon, QualityMaterialIcon, ServicingBeyondIcon, CustomerCentricIcon, YearWarranty, QuickInstallIcon, ArrowRotateRight } from '../components/Icons'
import { graphql } from "gatsby"

const HelpBlockBg = styled.div`
  background-image:${props => props.bgImage};
  position: absolute;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #CB1517;
  bottom:0;
  width:100%;
  border-radius: 25px;
  height: 100%;
  @media(min-width:576px){
    height: 280px;
  }
  @media(min-width:992px){
    border-radius: 25px 0 0 25px;
    width: calc(100% - 105px);
  }
`
const WhychoseBg = styled.div`
  background-image:${props => props.bgImage};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.2;
`
const WhychoseBeforeBg = styled.div`
  position:relative;
  &:before{
    content:'';
    background-image:${props => props.bgBefore};
    background-repeat: no-repeat;
    background-position: -92px 0;
    position:absolute;
    width: 92px;
    height: 190px;
    bottom: calc(100% - 60px);
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
`
const CategoryBg = styled.div`
  background-image:${props => props.bgImage};
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  opacity: 0.7;
`

const CardProduct = styled.div`
    .card{
        background-color: #fff;
        border: none;
        border-radius: 25px;
        overflow: hidden;
        margin-bottom: 30px;
        @media (min-width:1600px){
            margin-bottom: 80px;
        }
        > .gatsby-image-wrapper{
            border-radius:25px 25px 0 0;
        }
        .card-title{
            position: relative;
            z-index: 1;
            text-align: center;            
            color:#0E0B2D;
            font-size: 20px;
            line-height: 30px;  
            font-family:'Industry', sans-serif;
            font-weight:bold;
            margin-bottom:10px;
            text-transform:uppercase;
            letter-spacing: -1px;
            @media (min-width:567px){
              display: flex;
              align-items: center;
              justify-content: center;
            }
            @media (min-width:768px){
              font-size: 22px;
            }
            @media (min-width:992px){
                font-size: 26px;
                line-height: 32px;
            }
            @media (min-width:1200px){
                font-size: 30px;
                line-height: 36px;
            }
        }
        .card-body{
            position: relative;
            border: none;
            background: transparent;
            top: 0;
            left: 0;
            right: 0;
            margin: 0;
            padding: 10px;
            margin-top: -50px;
            @media (min-width: 576px) {
              margin-top: -90px;
            }
            &:after{
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                filter: blur(1px);
                opacity: 0.7;
                background: #fff;
                background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(14,11,45,1) 100%);
                background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(14,11,45,1) 100%);
                background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(14,11,45,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#0e0b2d',GradientType=0 );
                border-radius: 25px;
            }
            .list-bullet-group{
                display:flex;
                align-items:center;
                justify-content: space-around;
                color:#0E0B2D;
                font-size:14px;
                font-weight:700;
                margin-bottom: 20px;
                @media(max-width:575.98px){
                    margin-bottom: 10px;
                    flex-direction: column;
                }
                @media(min-width:1200px){
                    font-size:16px;
                }
                > li{
                    margin:0;
                }
            }
        }
    }
`
const CardBodyInner = styled.div`
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
    z-index: 1;

`
const CardAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 -5px;
    @media(min-width:1200px){
      margin:0 -15px;
    }
    @media(max-width:575.98px){
        flex-direction:column;
    }
    .btn{
        position:relative;
        margin:0 5px;
        font-family: 'Industry-Demi', sans-serif;
        font-size: 16px;
        padding: 5px 15px;
        @media(min-width:768px){
          padding: 10px 15px;
        }
        .icon{
          height:30px;          
        }
        .text, .icon{
          line-height:30px;
        }
        @media(min-width:1200px){
          margin:0 15px;
          padding: 10px 30px;
        }
        @media(max-width:575.98px){
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            + .btn{
              margin-top:10px;
            }
        }
        &.btn-secondary{
          .icon{
            > svg{
              width: 30px;
              height: 30px;
            }
          }
        }

    }
`
const CardPriceSize = styled.div`
    color:#0E0B2D;
    display:flex;
    align-items:center;
    justify-content: space-evenly;
    font-size:18px;
    line-height:20px;
    margin:0 -5px 20px -5px;
    font-family:'Industry-Demi', sans-serif;
    @media(min-width:576px){
      font-size:20px;
      line-height:22px;
      margin:0 -10px 20px -10px;
    }
    small{
        font-size:14px;
        line-height:20px;
        @media(min-width:576px){
          font-size:20px;
          line-height:22px;
        }
    }
    strong{
      font-weight:400;
      color:#CB1517;
      @media(max-width:575.98px){
        display:block
      }
    }
`
const CardItemPriceSize = styled.div`
    display:flex;
    align-items:center;
    padding:0 5px;
    @media(min-width:576px){
    padding:0 10px;
    }
    &.price{
        @media(max-width:575.98px){
            flex-direction:column;
            align-items:initial;
        }
    }
    .icon{
        + .text{
            margin-left:10px;
        }
    }
`
const FooterBottom = styled.div`
  border-top: 1px solid rgb(64, 67, 80);
  text-align: center;
  padding: 20px 0;
  color: #a7a6b7;
  margin-top: 30px;
  @media(min-width:992px){
    margin-top: 50px;
  }

`
class MetalBuildingLanding extends React.Component {
  constructor(props) {
    super(props)   
    this.toggleActive = this.toggleActive.bind(this);
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.scrollToNode = this.scrollToNode.bind(this);
    this.state = {
      isDark:true,
      show: false,
    }
  }
  scrollToNode(node) {
    node.scrollIntoView({ behavior: 'smooth' });
  }
  componentWillUnmount() {
    document.body.classList.remove('menu-open')
  }
  toggleActive() { 
    this.setState({
      isDark: ! this.state.isDark
    })
    document.body.classList.toggle('menu-open', this.state.isDark)
  }
  handleClose() {
    this.setState({
      show: false,
    })
  }

  handleShow() {
    this.setState({
      show: true,
    })
  }
  
  render() {
    const { show } = this.state
    const productsData = this.props.data.markdownRemark.frontmatter;
    const { location }= this.props;
    let formId = "Request a Quote";
    let formId1 = "Get Started";

    return (
      <Layout pathname = "metal-buildings-for-sale" location = {location}>
        <SEO title="Affordable Custom Metal Buildings for Sale - Carports R US" description="No matter what you need, we’ve got a great-looking, reliable, low-maintenance metal building to suit your needs.Just let us know what you need!" />
        <Modal
          show={show}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Request a Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GetTouchForm location = {location} formId = { formId }  />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <div className='body-overlay'></div>
        <header className='header inner-header'>
          <Navbar bg="primary" variant="dark" expand="xl">
            <Container>
                <Navbar.Brand href='/'><Logo /></Navbar.Brand>                             
                <Navbar.Collapse id="navbar-inner">
                    <Nav className="mr-auto navbar-top">
                      <div className="dropdown nav-item">
                        <span class="nav-link" onClick={() => this.scrollToNode(this.home)}>Home</span>
                      </div>

                      <div className="dropdown nav-item">
                        <span class="nav-link" onClick={() => this.scrollToNode(this.building)}>Metal Buildings</span>
                      </div>

                      <div className="dropdown nav-item">
                        <span class="nav-link" onClick={() => this.scrollToNode(this.rto)}>RTO & Financing</span>
                      </div>
                      <div className="dropdown nav-item" onClick={this.handleShow}>
                        <span class="nav-link">Get Your Quote</span>
                      </div>
                      <div className="dropdown nav-item nav-item-design">
                        <span class="nav-link" href="https://carportrus.sensei3d.com/" target="_blank" rel="noopener noreferrer">Design Your Building</span>
                      </div>
                    </Nav>
                </Navbar.Collapse>
                <NabbarTop />
                <Navbar.Toggle aria-controls="navbar-inner" onClick={this.toggleActive}>
                    <div></div>
                    <div></div>
                    <div></div>
                </Navbar.Toggle>
            </Container>
          </Navbar>
        </header>
        <main>
          {/* HeroLanding  */}
          <Section className='section section-hero' bgImage={`url(${HeroNatureBg})`} ref={(node) => this.home = node}>
            <div className="container">
              <div className="hero-cover">
                <Row className="hero-row">
                  <Col md={6} lg={6} xl={5} className="hero-content">
                    <h1 className="hero-title">Metal buildings</h1>
                    <span className="hero-subtitle">For Sale</span>
                    <p className="hero-lead">Built Stronger To Last Longer</p>
                    <button type='button' className="btn btn-primary" onClick={this.handleShow}>
                      <span className="text">Get Your Free Quote Now</span>
                      <span className="icon"><ArrowRotateRight/></span>
                    </button>
                  </Col>
                  <Col md={6} lg={6} xl={7} className="hero-carport">
                    <figure>
                      <Image name="metal-building-white.png" alt="Metal Building" />
                    </figure>
                  </Col>
                </Row>
              </div>
            </div>
          </Section>
      
          {/* Why Choose */}
          <Section className='section section-whychoose' >
            <div className="container-fluid">
              <div className="row-choose">
                <div className="left-choose">
                  <div className="panel-wychoose">
                    <WhychoseBeforeBg bgBefore={`url(${TopPinGraphic})`} />
                    <WhychoseBg bgImage={`url(${WhyChooseBg})`} />
                    <div className="panel-heading">
                      <span className="sub-title">Why Choose</span>
                      <h2 className="title">Carport R US</h2>
                    </div>
                    <div className="panel-body">
                      <p>At Carports R Us, our vision is really pretty simple. We’re here to build our business with customers just like you. We work to ensure that you’re thrilled with our product, and we’re committed to help you receive the metal structure you want with the quickest delivery and installation as possible!</p>
                    </div>
                  </div>
                </div>
                <div className="right-choose">
                  <Accordion className="list-service" defaultActiveKey="0">            
                    <div className="list-item-service">
                      <Accordion.Toggle className='accordian-item' as={Button} variant="link" eventKey="0">
                        <div className="list-icon-item">
                          <span className="icon purple"><FairPriceIcon/></span>
                          <span className="text">Fair Price Policy</span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <div className="list-item-content">We’re here to get you a metal building to meet your needs and your budget.</div>
                      </Accordion.Collapse>
                    </div>
      
                    
                    <div className="list-item-service">
                      <Accordion.Toggle className='accordian-item' as={Button} variant="link" eventKey="1">
                        <div className="list-icon-item">
                          <span className="icon pink"><CustomerCentricIcon/></span>
                          <span className="text">Customer Centric</span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <div className="list-item-content">You, our customers, are the reason why we’re here! We exist to serve your needs.</div>
                      </Accordion.Collapse>
                    </div>
                    
                    <div className="list-item-service">
                      <Accordion.Toggle className='accordian-item' as={Button} variant="link" eventKey="2">
                        <div className="list-icon-item">
                          <span className="icon yellow"><QualityMaterialIcon/></span>
                          <span className="text">Quality Materials</span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <div className="list-item-content">We only use the best materials to produce metal buildings in 12-gauge and 14-gauge options.</div>
                      </Accordion.Collapse>
                    </div>
      
                    <div className="list-item-service">
                      <Accordion.Toggle className='accordian-item' as={Button} variant="link" eventKey="3">
                        <div className="list-icon-item">
                          <span className="icon building"><YearWarranty/></span>
                          <span className="text">20-Year Warranty</span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <div className="list-item-content">We provide a 20-year warranty for the frame and roof, and a 30-day workmanship warranty.</div>
                      </Accordion.Collapse>
                    </div>
                    
                    <div className="list-item-service">
                      <Accordion.Toggle className='accordian-item' as={Button} variant="link" eventKey="4">
                        <div className="list-icon-item">
                          <span className="icon red"><ServicingBeyondIcon/></span>
                          <span className="text">Serving NC & Beyond</span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                        <div className="list-item-content">Of course we serve North Carolina, but we also serve 4 other surrounding states as well.</div>
                      </Accordion.Collapse>
                    </div>
                    <div className="list-item-service">
                      <Accordion.Toggle className='accordian-item' as={Button} variant="link" eventKey="5">
                        <div className="list-icon-item">
                          <span className="icon blue"><QuickInstallIcon/></span>
                          <span className="text">Quick Installation</span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="5">
                        <div className="list-item-content">We get you your building fast! We can usually install within 3-4 weeks.</div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
          </Section>
      
          {/* Product Section */}
          <Section className='section section-category'  ref={(node) => this.building = node}  bgAfter={`url(${TopLeftGraphic})`} bgBefore={`url(${BottomRightGraphic})`}>
            <CategoryBg bgImage={`url(${ProductBg})`} />
            <Container>
              <div className="nav-tabs-pill">
                <Tabs defaultActiveKey="garage" id="navTabCategory">
                  <Tab
                    eventKey="garage"                  
                    title={
                      <>
                        <span className="icon"><GarageCategoryIcon /></span>
                        <span className="text">Garages</span>
                      </>
                    }
                  >
                    <Row className="gutters-40">
                      {productsData.garageProducts.map((key,index) => {
                        return(
                          <Col lg={6} key={index}>
                            <CardProduct className="card-product">
                              <Card>
                                <Image name={key.imageName} alt={key.imageName} />                
                                <Card.Body>
                                  <Card.Title>{key.name}</Card.Title>
                                  <CardBodyInner>
                                    <CardPriceSize>
                                        <CardItemPriceSize className="price">
                                          <small>Starting Price:</small><strong>{key.price}</strong>
                                        </CardItemPriceSize>
                                        <CardItemPriceSize className="measure">
                                            <span className="icon"><MeasureIcon /></span>
                                            <span className="text">{key.width}X{key.length}X{key.height}</span>
                                        </CardItemPriceSize>
                                    </CardPriceSize>
                                    <ListBulletGroup className="list-bullet-group">
                                      <ListBulletItemGroup>RTO & Financing Available</ListBulletItemGroup>
                                      <ListBulletItemGroup>Free Delivery & Installation</ListBulletItemGroup>
                                    </ListBulletGroup>
                                    <CardAction>
                                      <button onClick={this.handleShow} className="btn btn-primary"><span className="text">Get Your Price</span><span className="icon"><ArrowCircleRight/></span></button>
                                      <a href="tel:8773304846" className="btn btn-secondary"><span className="icon"><PhoneIcon/></span><span className="text">(877) 330-4846</span></a>
                                    </CardAction>
                                  </CardBodyInner>
                                </Card.Body>
                              </Card>
                            </CardProduct>
                          </Col>
                        )
                      })}
                    </Row>
                  </Tab>
      
                  <Tab
                    eventKey="barns"
                    title={
                      <>
                        <span className="icon"><GarageCategoryIcon /></span>
                        <span className="text">Barns</span>
                      </>
                    }
                  >
                    <Row className="gutters-40">
                      {productsData.barnProducts.map((key,index) => {
                        return(
                          <Col lg={6} key={index}>
                            <CardProduct className="card-product">
                              <Card>
                                <Image name={key.imageName} alt={key.imageName} />                
                                <Card.Body>
                                  <Card.Title>{key.name}</Card.Title>
                                  <CardBodyInner>
                                    <CardPriceSize>
                                        <CardItemPriceSize className="price">
                                          <small>Starting Price:</small><strong>{key.price}</strong>
                                        </CardItemPriceSize>
                                        <CardItemPriceSize className="measure">
                                            <span className="icon"><MeasureIcon /></span>
                                            <span className="text">{key.width}X{key.length}X{key.height}</span>
                                        </CardItemPriceSize>
                                    </CardPriceSize>
                                    <ListBulletGroup className="list-bullet-group">
                                      <ListBulletItemGroup>RTO & Financing Available</ListBulletItemGroup>
                                      <ListBulletItemGroup>Free Delivery & Installation</ListBulletItemGroup>
                                    </ListBulletGroup>
                                    <CardAction>
                                      <button onClick={this.handleShow} className="btn btn-primary"><span className="text">Get Your Price</span><span className="icon"><ArrowCircleRight/></span></button>
                                      <a href="tel:8773304846" className="btn btn-secondary"><span className="icon"><PhoneIcon/></span><span className="text">(877) 330-4846</span></a>
                                    </CardAction>
                                  </CardBodyInner>
                                </Card.Body>
                              </Card>
                            </CardProduct>
                          </Col>
                        )
                      })}
                    </Row>
                  </Tab>
      
                  <Tab
                    eventKey="carports"
                    title={
                      <>
                        <span className="icon"><GarageCategoryIcon /></span>
                        <span className="text">Carports</span>
                      </>
                    }
                  >
                    <Row className="gutters-40">
                      {productsData.carpotProducts.map((key,index) => {
                        return(
                          <Col lg={6} key={index}>
                            <CardProduct className="card-product">
                              <Card>
                                <Image name={key.imageName} alt={key.imageName} />                
                                <Card.Body>
                                  <Card.Title>{key.name}</Card.Title>
                                  <CardBodyInner>
                                    <CardPriceSize>
                                        <CardItemPriceSize className="price">
                                          <small>Starting Price:</small><strong>{key.price}</strong>
                                        </CardItemPriceSize>
                                        <CardItemPriceSize className="measure">
                                            <span className="icon"><MeasureIcon /></span>
                                            <span className="text">{key.width}X{key.length}X{key.height}</span>
                                        </CardItemPriceSize>
                                    </CardPriceSize>
                                    <ListBulletGroup className="list-bullet-group">
                                      <ListBulletItemGroup>RTO & Financing Available</ListBulletItemGroup>
                                      <ListBulletItemGroup>Free Delivery & Installation</ListBulletItemGroup>
                                    </ListBulletGroup>
                                    <CardAction>
                                      <button onClick={this.handleShow} className="btn btn-primary"><span className="text">Get Your Price</span><span className="icon"><ArrowCircleRight/></span></button>
                                      <a href="tel:8773304846" className="btn btn-secondary"><span className="icon"><PhoneIcon/></span><span className="text">(877) 330-4846</span></a>
                                    </CardAction>
                                  </CardBodyInner>
                                </Card.Body>
                              </Card>
                            </CardProduct>
                          </Col>
                        )
                      })}
                    </Row>
                  </Tab>
      
                  <Tab
                    eventKey="rv-covers"
                    title={
                      <>
                        <span className="icon"><GarageCategoryIcon /></span>
                        <span className="text">RV Covers</span>
                      </>
                    }
                  >
                    <Row className="gutters-40">
                      {productsData.rvCoverProducts.map((key,index) => {
                        return(
                          <Col lg={6} key={index}>
                            <CardProduct className="card-product">
                              <Card>
                                <Image name={key.imageName} alt={key.imageName} />                
                                <Card.Body>
                                  <Card.Title>{key.name}</Card.Title>
                                  <CardBodyInner>
                                    <CardPriceSize>
                                        <CardItemPriceSize className="price">
                                          <small>Starting Price:</small><strong>{key.price}</strong>
                                        </CardItemPriceSize>
                                        <CardItemPriceSize className="measure">
                                            <span className="icon"><MeasureIcon /></span>
                                            <span className="text">{key.width}X{key.length}X{key.height}</span>
                                        </CardItemPriceSize>
                                    </CardPriceSize>
                                    <ListBulletGroup className="list-bullet-group">
                                      <ListBulletItemGroup>RTO & Financing Available</ListBulletItemGroup>
                                      <ListBulletItemGroup>Free Delivery & Installation</ListBulletItemGroup>
                                    </ListBulletGroup>
                                    <CardAction>
                                      <button onClick={this.handleShow} className="btn btn-primary"><span className="text">Get Your Price</span><span className="icon"><ArrowCircleRight/></span></button>
                                      <a href="tel:8773304846" className="btn btn-secondary"><span className="icon"><PhoneIcon/></span><span className="text">(877) 330-4846</span></a>
                                    </CardAction>
                                  </CardBodyInner>
                                </Card.Body>
                              </Card>
                            </CardProduct>
                          </Col>
                        )
                      })}
                    </Row>
                  </Tab>
      
                  <Tab
                    eventKey="commercial"
                    title={
                      <>
                        <span className="icon"><GarageCategoryIcon /></span>
                        <span className="text">Commercial</span>
                      </>
                    }
                  >
                    <Row className="gutters-40">
                      {productsData.commercialProducts.map((key,index) => {
                        return(
                          <Col lg={6} key={index}>
                            <CardProduct className="card-product">
                              <Card>
                                <Image name={key.imageName} alt={key.imageName} />                
                                <Card.Body>
                                  <Card.Title>{key.name}</Card.Title>
                                  <CardBodyInner>
                                    <CardPriceSize>
                                        <CardItemPriceSize className="price">
                                            <small>Starting Price:</small><strong>{key.price}</strong>
                                        </CardItemPriceSize>
                                        <CardItemPriceSize className="measure">
                                            <span className="icon"><MeasureIcon /></span>
                                            <span className="text">{key.width}X{key.length}X{key.height}</span>
                                        </CardItemPriceSize>
                                    </CardPriceSize>
                                    <ListBulletGroup className="list-bullet-group">
                                      <ListBulletItemGroup>RTO & Financing Available</ListBulletItemGroup>
                                      <ListBulletItemGroup>Free Delivery & Installation</ListBulletItemGroup>
                                    </ListBulletGroup>
                                    <CardAction>
                                      <button onClick={this.handleShow} className="btn btn-primary"><span className="text">Get Your Price</span><span className="icon"><ArrowCircleRight/></span></button>
                                      <a href="tel:8773304846" className="btn btn-secondary"><span className="icon"><PhoneIcon/></span><span className="text">(877) 330-4846</span></a>
                                    </CardAction>
                                  </CardBodyInner>
                                </Card.Body>
                              </Card>
                            </CardProduct>
                          </Col>
                        )
                      })}
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </Container>
          </Section>
          
          {/* Get Started */}
          <Section className='section section-quote' bgImage={`url(${NatureBg})`}>
              <div className="free-quote">
                <div className="left-quote">
                  <div className="panel-quote">
                    <div className="panel-heading">
                      <span className="sub-title">Ready To</span>
                      <h2 className="title">Get Started?</h2>
                      <p className="lead">Our Skilled Building Consultants will Help in Designing Your Dream Building Exactly The Way You Need</p>
                    </div>
                    <div className="panel-body">
                      <GetTouchForm location = {location} formId = { formId1 } />
                    </div>
                  </div>
                </div>
                <div className="right-quote">
                  <Image name="metal-building-red.png" alt="metal-building" />
                </div>
              </div>
          </Section>
          
          {/* Payment Section */}
          <Section className='section section-paymethod' ref={(node) => this.rto = node} bgAfter={`url(${FinancingBg})`}>
            <Container>
              <div className="section-payment">
                <div className="section-header">
                  <h2 className="title"><strong>Flexible</strong> Payment Method</h2>
                  <span className="sub-title">Break away from the status quo & buy your dream metal building today with easy and actionable Rent to Own & Financing programs.</span>
                </div>
                <div className="section-body">
                  <Row  className="card-payment-group">
                    <Col xl={3} md={6}>
                      <Card className='card-payment'>
                        <Card.Header>
                          <Image name="rto.jpg" alt="rto" />
                          <Card.Title><span className="legend">Rent to Own</span></Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <ListBulletGroup className="list-bullet-group">
                            <ListBulletItemGroup>No Credit Check</ListBulletItemGroup>
                            <ListBulletItemGroup>Instant Approval</ListBulletItemGroup>
                            <ListBulletItemGroup>Month-To-Month Contract</ListBulletItemGroup>
                            <ListBulletItemGroup>Pay Off Anytime</ListBulletItemGroup>
                          </ListBulletGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xl={3} md={6}>
                      <Card className='card-payment'>
                        <Card.Header>
                          <Image name="financing.jpg" alt="financing" />
                          <Card.Title><span className="legend">Financing Plan</span></Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <ListBulletGroup className="list-bullet-group">
                            <ListBulletItemGroup>Quick, Easy & Transparent</ListBulletItemGroup>
                            <ListBulletItemGroup>No Pre-Payment Penalty</ListBulletItemGroup>
                            <ListBulletItemGroup>Hassle-Free Process</ListBulletItemGroup>
                            <ListBulletItemGroup>Flexible Monthly Payment Options</ListBulletItemGroup>
                          </ListBulletGroup>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xl={3} md={12}>
                      <div className="payment-plan">
                        <h3 className="title">Keep Your Metal Building Financial Planning Stable</h3>
                        <div className='btn btn-secondary'>
                          <span className="text" onClick={this.handleShow} >APPLY NOW</span>
                        </div>
                      </div>
                    </Col>
                    <Col className="finance-asist">
                      <figure className="figure">
                        <Image name="men.png" alt="men" />
                      </figure>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </Section>
          
          {/* Our Specialties Section */}
          <Section className='section section-specialties'>
            <Container>
              <div className="section-special">
                <div className="section-header">
                  <span className="sub-title">Learn more about what we have to offer.</span>
                  <h2 className="title">OUR SPECIALTIES AT CARPORTS R US INCLUDE</h2>
                </div>
                <div className="section-body">
                  <Row className="card-icon-group">
                    <Col xl={3} md={6}>
                      <Card className='card-icon'>
                        <span className="card-circle"><CustomDesignIcon fill='#fff'/></span>
                        <Card.Body>
                          <Card.Title>Custom Design & Construction</Card.Title>
                          <Card.Text>Use our 3D Building Design Tool (with real-time pricing!) to create your own building plan.</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xl={3} md={6}>
                      <Card className='card-icon'>
                        <span className="card-circle"><DedicatedTeamIcon fill='#fff'/></span>
                        <Card.Body>
                          <Card.Title>Experienced, Dedicated Team</Card.Title>
                          <Card.Text>We’ve been providing custom steel structures for years, and we’re equipped to serve you, too!</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xl={3} md={6}>
                      <Card className='card-icon'>
                        <span className="card-circle"><ClipboardIcon fill='#fff'/></span>
                        <Card.Body>
                          <Card.Title>A Wide Variety of Metal Building</Card.Title>
                          <Card.Text>We do carports, garages, barns, storage buildings, workshops, clear span facilities, insulated buildings, and more!</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xl={3} md={6}>
                      <Card className='card-icon'>
                        <span className="card-circle"><DepositIcon fill='#fff'/></span>
                        <Card.Body>
                          <Card.Title>Excellent Financing Options</Card.Title>
                          <Card.Text>We offer ways to help you afford the building you need, including financing and rent-to-own.</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </Section>
          
          {/* Section Helpblock */}
          <Section className='section section-helpblock'>
            <Container>
              <div className="help-block">
                <HelpBlockBg bgImage={`url(${CallActionBg})`} />
                <Row className="align-items-end">
                  <Col md={12} lg>
                    <div className="help-panel" >
                      <figure className="help-figure">
                        <Image name="woman.png" alt="Phone Call" />
                      </figure>
                      <div className="help-content">
                        <h2 className="title">Ready to Get Started?</h2>
                        <p>We're committed to help you receive the metal structure you want with the quickest delivery and installation as possible!</p>
                        <a href='tel:8773304846' className='btn btn-white'>
                          <span className="icon"><PhoneCallIcon /></span>
                          <span className="text">(877) 330-4846</span>
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col xs="5" className="help-carport">
                    <figure><Image name="metal-building-yellow.png" alt="Metal Building" /></figure>
                  </Col>
                </Row>
              </div>
            </Container>            
          </Section>
      
          {/* Footer Section */}
          <Section className='section footer section-foot' bgImage={`url(${FooterPattern})`}>
            <Container>
              <div className="section-dealer">
                <figure className="figure-brand"><img src={BrandLogo} alt="Carport R Us" /></figure>              
                <span className="subtitle-fieldset"><span className="legend">The Best Regional Dealer of</span></span>
                <h2 className="title">Metal Buildings in the Eastern US!</h2>
                <p className="lead">We understand that when it comes to a metal building investment, price matters to you. We believe in providing you with a metal building that will meet both your needs and your budget.</p>
              </div>
            </Container>
              <Container>
                <div className="social-info">
                  <Row>
                    <Col md={6} lg={3}>
                      <div className="footer-top-cnt">
                        <h5 className="footer-heading">
                          <PhoneIcon stroke="#fff" fill="#fff" />
                          <span>Call Us</span>
                        </h5>
                        <div className="footer-text">                
                          <a href="tel:8773304846">Phone: (877) 330-4846</a>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} lg={3}>
                    <div className='footer-top-cnt'>
                      <h5 className='footer-heading'><EmailIcon stroke='#fff' fill='#fff' /><span>Email Us</span></h5>
                        <a className='footer-text' href="mailto:carportrus@gmail.com" target="_blank" rel="noopener noreferrer">carportrus@gmail.com</a>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                      <div className="footer-top-cnt">
                        <h5 className="footer-heading">
                          <MapIcon stroke="#fff" fill="#fff" />
                          <span>Find Us</span>
                        </h5>
                        <p className="footer-text">
                          915, Newsome St. Mount Airy, NC 27030
                        </p>
                      </div>
                    </Col>
                    <Col md={6} lg={{span:3, offset:0}}>
                      <ul className='social-icons'>
                        <li className='facebook'><Link to='https://www.facebook.com/CarportRUs' target="_blank" rel="noopener noreferrer"><FacebookIcon fill='#fff' /></Link></li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Container>
            <FooterBottom>
              <Container>© {new Date().getFullYear()} Carports R Us. All RightsReserved.</Container>
            </FooterBottom>
          </Section>
        </main>
      </Layout>
    )
  }
}
    
export default MetalBuildingLanding

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        metaTitle
        metaDesc
        garageProducts {
            name
            price
            width
            length
            height
            imageName
        }
        barnProducts {
            name
            price
            width
            length
            height
            imageName
        }
        carpotProducts {
            name
            price
            width
            length
            height
            imageName
        }
        rvCoverProducts {
            name
            price
            width
            length
            height
            imageName
        }
        commercialProducts {
            name
            price
            width
            length
            height
            imageName
        }
     }
    html
    }
  }
`